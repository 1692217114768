import Swiper from 'swiper/swiper-bundle.esm.js'

class Slider {
  constructor () {
    this.init()
  }

  init () {
    // Image gallery
    const sliderGallery = document.querySelector('.slider-gallery__container')
    const columns = sliderGallery ? parseFloat(sliderGallery.dataset.columns.replace(/,/g, '.')) : 2
    const offset = sliderGallery && +sliderGallery.dataset.shownext ? 0.5 : 0
    const spaceBetweenMobile = sliderGallery ? +sliderGallery.dataset.spacebetweenmobile : 30
    const spaceBetweenDesktop = sliderGallery ? +sliderGallery.dataset.spacebetweendesktop : 30
    const sliderSection = new Swiper('.slider-gallery .swiper-container', { // eslint-disable-line
      // Optional parameters
      direction: 'horizontal',
      loop: false,
      slidesPerView: 1.25,
      spaceBetween: spaceBetweenMobile,
      // Responsive
      breakpoints: {
        992: {
          slidesPerView: columns + offset,
          spaceBetween: spaceBetweenDesktop
        }
      },

      // Navigation arrows
      navigation: {
        nextEl: '.js-swiper-next',
        prevEl: '.js-swiper-prev'
      }
    })

    // Customers feedback
    const customersFeedbackSection = new Swiper('.customers-feedback .swiper-container', { // eslint-disable-line
      // Optional parameters
      direction: 'horizontal',
      loop: false,
      slidesPerView: 1,
      spaceBetween: 50,

      // Navigation arrows
      navigation: {
        nextEl: '.js-customers-feedback-swiper-next',
        prevEl: '.js-customers-feedback-swiper-prev'
      },

      // Events
      pagination: {
        el: '.customers-feedback__pagination'
      }
    })
    // Timeline section freemode slider.
    for (const el of Array.from(
      document.querySelectorAll('.timeline')
    )) {
      new Swiper(el.querySelector('.timeline-swiper-container'), { // eslint-disable-line
        freeMode: true,
        grabCursor: true,
        slidesPerView: 'auto',
        // Navigation arrows
        navigation: {
          nextEl: el.querySelector('.js-swiper-button-next'),
          prevEl: el.querySelector('.js-swiper-button-prev')
        }
      })
    }
  }
}

export default Slider
