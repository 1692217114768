import './scss/style.scss'
import Header from './js/template-parts/common/header.js'
import ModulesLoader from './js/modules-loader.js'
import Barba from './js/lib/barba.js'
//  import Cookies from './js/template-parts/popups/cookies.js'
import ScrollMagic from 'scrollmagic'
import DisableScroll from './js/lib/disable-scroll.js'

// Throttles calls to a callback function for a specified amount of time.
window.throttle = (callback, wait = 100) => {
  let timer = null

  return function () {
    const args = arguments
    const context = this
    if (timer === null) {
      timer = setTimeout(() => {
        callback.apply(context, args)
        timer = null
      }, wait)
    }
  }
}

// Load objects
document.addEventListener('DOMContentLoaded', function (event) {
  // Header
  window.header = new Header()

  // BarbaJS
  window.barba = new Barba()
})

window.addEventListener('load', function (event) {
  // Ajax requests
  window.ajaxReq = function ajaxReq () {
    if (window.XMLHttpRequest) {
      return new XMLHttpRequest()
    } else if (window.ActiveXObject) {
      return new ActiveXObject('Microsoft.XMLHTTP')
    } else {
      console.log('Browser does not support XMLHTTP.')
      return false
    }
  }

  window.loadJS = (() => {
    const callbacks = {}
    const loaded = {}

    return (file, callback) => {
      if (typeof callbacks[file] === 'object') {
        if (typeof loaded[file] === 'undefined') {
          callbacks[file].push(callback)
        } else {
          callback()
        }
        return
      }

      callbacks[file] = [callback]
      var jsElm = document.createElement('script')
      jsElm.type = 'application/javascript'
      jsElm.src = file
      jsElm.addEventListener('load', () => {
        loaded[file] = true
        const fileCallbacks = callbacks[file]
        for (let i = 0; i < fileCallbacks.length; i++) {
          const callback = fileCallbacks[i]
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      document.body.appendChild(jsElm)
    }
  })()

  window.googleapi = false
  window.modulesLoader = new ModulesLoader()
  window.modulesLoader.loadModules()

  window.disableScroll = new DisableScroll()

  window.animationScenes(new ScrollMagic.Controller())
})


