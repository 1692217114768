class Accordion {
  constructor() {
    this.accordions = document.getElementsByClassName('accordion');

    if (this.accordions.length) {
      this.init();
    }
  }

  init () {
    // for (let i = 0; i < this.accordions.length; i++) {
    //   const items = this.accordions[i].querySelectorAll('.accordion__input')

    //   for (let i = 0; i < items.length; i++) {
    //     items[i].addEventListener('click', function () {
    //       items[i].closest('.accordion__item').classList.toggle('toggled')
    //     })
    //   }
    // }
    Array.from(this.accordions).forEach(accordion => {
      accordion.addEventListener('click', () => {
        const items = accordion.querySelectorAll('.accordion__item');
        for (const item of items) {
          item.addEventListener('click', () => {
            item.closest('.accordion__item').classList.toggle('toggled');
          });
        }
       
        console.log("Accordion clicked");
      });
    });
  }
}

export default Accordion
